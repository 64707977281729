html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
	margin: 0;
	padding: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}

* {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

html,
body {
	margin: 0;
	scroll-behavior: smooth;
	/* background-color: #f9fafb; */
	background-color: #ffffff;
}

/* HTML5 display-role reset for older browsers */
svg,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
	display: block;
}

blockquote,
q {
	quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
	content: '';
	content: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

a {
	color: inherit;
	text-decoration: none;
}

/* COLORS */
:root {
	/* Primary */
	/* --ltPrimary: #1da099; */
	--ltPrimary: #0e7673;
	/* --ltPrimary: #0e6d68; */
	--ltPrimary400: #4cc5b4;
	--ltPrimary300: #73e2c8;
	--ltPrimary200: #a5f5db;
	--ltPrimary100: #d1fae9;
	/* Secondary */
	--ltSecondary: #ffd023;
	--ltSecondary400: #ffde65;
	--ltSecondary300: #ffeca7;
	--ltSecondary200: #fff6d3;
	--ltSecondary100: #fffae9;
	/* Success */
	--ltSuccessDark: #0caf60;
	--ltSuccessBase: #0ba259;
	--ltSuccessLight: #55c790;
	/* Warning */
	--ltWarningDark: #e6bb20;
	--ltWarningBase: #ffd023;
	--ltWarningLight: #ffde65;
	/* Error */
	--ltErrorDark: #fd4f4f;
	--ltErrorBase: #fd6a6a;
	--ltErrorLight: #fd8181;
	/* Greyscale */
	--ltGreyscale50: #fafafa;
	--ltGreyscale100: #f9fafb;
	--ltGreyscale200: #f1f2f4;
	--ltGreyscale300: #eeeff2;
	--ltGreyscale400: #cbd5e0;
	--ltGreyscale500: #a0aec0;
	--ltGreyscale600: #718096;
	--ltGreyscale700: #323b49;
	--ltGreyscale900: #1f2937;
	/* --ltGreyscale900: #111827; */
	/* Additional Colors */
	--ltWhite: #ffffff;
	--ltOrange: #fe964a;
	--ltBlue: #0062ff;
	--ltPurple: #8c62ff;
}

/* HEADINGS */
.lth1_regular {
	font-size: 48px;
	font-weight: 400;
}

.lth1_medium {
	font-size: 48px;
	font-weight: 500;
}

.lth1_semibold {
	font-size: 48px;
	font-weight: 600;
}

.lth1_bold {
	font-size: 48px;
	font-weight: 700;
}

.lth2_regular {
	font-size: 40px;
	font-weight: 400;
}

.lth2_medium {
	font-size: 40px;
	font-weight: 500;
}

.lth2_semibold {
	font-size: 40px;
	font-weight: 600;
}

.lth2_bold {
	font-size: 40px;
	font-weight: 700;
}

.lth3_regular {
	font-size: 30px;
	font-weight: 400;
}

.lth3_medium {
	font-size: 30px;
	font-weight: 500;
}

.lth3_semibold {
	font-size: 30px;
	font-weight: 600;
}

.lth3_bold {
	font-size: 30px;
	font-weight: 700;
}

.lth4_regular {
	font-size: 24px;
	font-weight: 400;
}

.lth4_medium {
	font-size: 24px;
	font-weight: 500;
}

.lth4_semibold {
	font-size: 24px;
	font-weight: 600;
}

.lth4_bold {
	font-size: 24px;
	font-weight: 700;
}

.lth5_regular {
	font-size: 20px;
	font-weight: 400;
}

.lth5_medium {
	font-size: 20px;
	font-weight: 500;
}

.lth5_semibold {
	font-size: 20px;
	font-weight: 600;
}

.lth5_bold {
	font-size: 20px;
	font-weight: 700;
}

.lth6_regular {
	font-size: 18px;
	font-weight: 400;
}

.lth6_medium {
	font-size: 18px;
	font-weight: 500;
}

.lth6_semibold {
	font-size: 18px;
	font-weight: 600;
}

.lth6_bold {
	font-size: 18px;
	font-weight: 700;
}

/* BODY */

/* xs */
.ltbody_xs__regular {
	font-size: 6px;
	font-weight: 400;
}

.ltbody_xs__medium {
	font-size: 6px;
	font-weight: 500;
}

.ltbody_xs__semibold {
	font-size: 6px;
	font-weight: 600;
}

.ltbody_xs__bold {
	font-size: 6px;
	font-weight: 700;
}

/* sm */

.ltbody_sm__regular {
	font-size: 12px;
	font-weight: 400;
}

.ltbody_sm__medium {
	font-size: 12px;
	font-weight: 500;
}

.ltbody_sm__semibold {
	font-size: 12px;
	font-weight: 600;
}

.ltbody_sm__bold {
	font-size: 12px;
	font-weight: 700;
}

/* md */

.ltbody_md__regular {
	font-size: 14px;
	font-weight: 400;
}

.ltbody_md__medium {
	font-size: 14px;
	font-weight: 500;
}

.ltbody_md__semibold {
	font-size: 14px;
	font-weight: 600;
}

.ltbody_md__bold {
	font-size: 14px;
	font-weight: 700;
}

/* lg */

.ltbody_lg__regular {
	font-size: 16px;
	font-weight: 400;
}

.ltbody_lg__medium {
	font-size: 16px;
	font-weight: 500;
}

.ltbody_lg__semibold {
	font-size: 16px;
	font-weight: 600;
}

.ltbody_lg__bold {
	font-size: 16px;
	font-weight: 700;
}

/* xlg */

.ltbody_xlg__regular {
	font-size: 18px;
	font-weight: 400;
}

.ltbody_xlg__medium {
	font-size: 18px;
	font-weight: 500;
}

.ltbody_xlg__semibold {
	font-size: 18px;
	font-weight: 600;
}

.ltbody_xlg__bold {
	font-size: 18px;
	font-weight: 700;
}

/* SPACING */
.p_5 {
	padding: 5px;
}

.p_10 {
	padding: 10px;
}

.p_20 {
	padding: 20px;
}

.py_10 {
	padding-top: 10px;
	padding-bottom: 10px;
}

.py_20 {
	padding-top: 10px;
	padding-bottom: 20px;
}

.px_10 {
	padding-left: 10px;
	padding-right: 10px;
}

.px_20 {
	padding-left: 20px;
	padding-right: 20px;
}

.pb_5 {
	padding-bottom: 5px;
}

.pt_5 {
	padding-top: 5px;
}

.pt_10 {
	padding-top: 10px;
}

.pt_20 {
	padding-top: 20px;
}

.pb_5 {
	padding-bottom: 5px;
}

.pb_10 {
	padding-bottom: 10px;
}

.pb_20 {
	padding-bottom: 20px;
}

.ml5 {
	margin-left: 5px;
}

.ml10 {
	margin-left: 10px;
}

.ml20 {
	margin-left: 20px;
}

.ml_auto {
	margin-left: auto;
}

.mr5 {
	margin-right: 5px;
}

.mr10 {
	margin-right: 10px;
}

.mr16 {
	margin-right: 16px;
}

.mb30 {
	margin-bottom: 30px;
}

.mb25 {
	margin-bottom: 25px;
}

.mb20 {
	margin-bottom: 20px;
}

.mb15 {
	margin-bottom: 15px;
}

.mb10 {
	margin-bottom: 10px;
}

.mb5 {
	margin-bottom: 5px;
}

.mt_2 {
	margin-top: 2px;
}

.mt_5 {
	margin-top: 5px;
}

.mt_10 {
	margin-top: 10px;
}

.mt_20 {
	margin-top: 20px;
}

.mt_30 {
	margin-top: 30px;
}

.m10 {
	margin: 10px;
}

.m20 {
	margin: 20px;
}

/* UTILS */
.flex {
	display: flex;
	align-items: center;
}

.flex-column {
	display: flex;
	flex-direction: column;
}

.justify-start {
	justify-content: start;
}

.justify-center {
	justify-content: center;
}
.justify-end {
	justify-content: end;
}

.justify-between {
	justify-content: space-between;
}

.gap20 {
	gap: 20px;
}

.gap10 {
	gap: 10px;
}

.flex_1 {
	flex: 1;
}

.relative {
	position: relative;
}

.fullWidth {
	width: 100%;
}

.talignRight {
	text-align: right;
}

.talignCenter {
	text-align: center;
}

.cursor_pointer {
	cursor: pointer;
}

.capitalize {
	text-transform: capitalize;
}

.t_nowrap {
	text-wrap: nowrap;
	white-space: nowrap;
	-moz-text-wrap: nowrap;
	-webkit-text-wrap: nowrap;
	-ms-text-wrap: nowrap;
}

.word_break_keep {
	word-break: keep-all;
	-moz-word-break: keep-all;
	-webkit-word-break: keep-all;
	-ms-word-break: keep-all;
}

.overflow_scroll_x {
	overflow-x: scroll;
}

.singleLine_ellipsis {
	display: -webkit-box;
	-webkit-box-orient: vertical;
	line-clamp: 1;
	-webkit-line-clamp: 1;
	overflow: hidden;
}

.doubleLine_ellipsis {
	display: -webkit-box;
	-webkit-box-orient: vertical;
	line-clamp: 2;
	-webkit-line-clamp: 2;
	overflow: hidden;
}

/* COLORS */
.primaryBase {
	color: var(--ltPrimary);
}

.ltSuccessBase {
	color: var(--ltSuccessBase);
}

.white {
	color: var(--ltWhite);
}

.orange {
	color: var(--ltOrange);
}

.errorBase {
	color: var(--ltErrorBase);
}

.errorDark {
	color: var(--ltErrorDark);
}

.ltGreyscale900 {
	color: var(--ltGreyscale900);
}

.ltGreyscale600 {
	color: var(--ltGreyscale600);
}

.ltGreyscale700 {
	color: var(--ltGreyscale700);
}

.ltGreyscale800 {
	color: var(--ltGreyscale800);
}

.ltGreyscale500 {
	color: var(--ltGreyscale500);
}

.ltGreyscale300 {
	color: var(--ltGreyscale300);
}

.ltGreyscale100 {
	color: var(--ltGreyscale100);
}

.text_underline {
	text-decoration: underline;
}

.text_line_through {
	text-decoration: line-through;
}

/* MATERIAL ICONS STYLES */
/* Rules for sizing the icon. */
.md-18 {
	font-size: 18px !important;
}

.md-24 {
	font-size: 24px !important;
}

.md-36 {
	font-size: 36px !important;
}

.md-48 {
	font-size: 48px !important;
}

.md-64 {
	font-size: 64px !important;
}

/* Rules for using icons as black on a light background. */
.md-dark {
	color: rgba(0, 0, 0, 0.54);
}

.md-dark.md-inactive {
	color: rgba(0, 0, 0, 0.26);
}

/* Rules for using icons as white on a dark background. */
.md-light {
	color: rgba(255, 255, 255, 1);
}

.md-light.md-inactive {
	color: rgba(255, 255, 255, 0.3);
}

/* Display */
.d_none {
	display: none;
}

.d_block {
	display: block;
}

.v_hidden{
	visibility: hidden;
}

.swiper-container-horizontal > .swiper-pagination-bullets {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 20px;
	background: #000;
}

.swiper-pagination .swiper-pagination-bullet-active.swipper_bullets_wrapper {
	width: 48px;
	border-radius: 50px;
	background: var(--ltPrimary);
}

.swipper_bullets_wrapper {
	width: 10px;
	height: 10px;
	border-radius: 50%;
	transition: 0.25s width ease;
}

dialog::backdrop {
	background: rgba(0, 0, 0, 0.36);
}

.react-datepicker-wrapper {
	display: initial !important;
}

.infinite-scroll-component::-webkit-scrollbar {
	display: none;
}

/* Breakpoints */

@media only screen and (min-width: 768px) and (max-width: 1024px) {
	/* HEADINGS */
	.lth1 {
		font-size: 40px;
	}

	.lth2 {
		font-size: 32px;
	}

	.lth3 {
		font-size: 24px;
	}

	.lth4 {
		font-size: 20px;
	}

	.lth5 {
		font-size: 18px;
	}

	.lth6 {
		font-size: 16px;
	}

	/* BODY */
	.ltbody_xlg__regular {
		font-size: 16px;
	}

	.ltbody_xlg__medium {
		font-size: 16px;
	}

	.ltbody_xlg__semibold {
		font-size: 16px;
	}

	.ltbody_xlg__bold {
		font-size: 16px;
	}

	.ltbody_lg__regular {
		font-size: 14px;
	}

	.ltbody_lg__medium {
		font-size: 14px;
	}

	.ltbody_lg__semibold {
		font-size: 14px;
	}

	.ltbody_lg__bold {
		font-size: 12px;
	}

	.ltbody_md__regular {
		font-size: 12px;
	}

	.ltbody_md__medium {
		font-size: 12px;
	}

	.ltbody_md__semibold {
		font-size: 12px;
	}

	.ltbody_md__bold {
		font-size: 12px;
	}

	.ltbody_sm__regular {
		font-size: 10px;
	}

	.ltbody_sm__medium {
		font-size: 10px;
	}

	.ltbody_sm__semibold {
		font-size: 10px;
	}

	.ltbody_sm__bold {
		font-size: 10px;
	}

	.ltbody_xs__regular {
		font-size: 8px;
	}

	.ltbody_xs__medium {
		font-size: 8px;
	}

	.ltbody_xs__semibold {
		font-size: 8px;
	}

	.ltbody_xs__bold {
		font-size: 8px;
	}

	.mb25 {
		margin-bottom: 16px;
	}
}
@media only screen and (min-width: 1024px) and (max-width: 1280px) {
	/* HEADINGS */
	.lth1 {
		font-size: 40px;
	}

	.lth2 {
		font-size: 32px;
	}

	.lth3 {
		font-size: 24px;
	}

	.lth4 {
		font-size: 20px;
	}

	.lth5 {
		font-size: 18px;
	}

	.lth6 {
		font-size: 16px;
	}

	/* BODY */
	.ltbody_xlg__regular {
		font-size: 16px;
	}

	.ltbody_xlg__medium {
		font-size: 16px;
	}

	.ltbody_xlg__semibold {
		font-size: 16px;
	}

	.ltbody_xlg__bold {
		font-size: 16px;
	}

	.ltbody_lg__regular {
		font-size: 14px;
	}

	.ltbody_lg__medium {
		font-size: 14px;
	}

	.ltbody_lg__semibold {
		font-size: 14px;
	}

	.ltbody_lg__bold {
		font-size: 12px;
	}

	.ltbody_md__regular {
		font-size: 12px;
	}

	.ltbody_md__medium {
		font-size: 12px;
	}

	.ltbody_md__semibold {
		font-size: 12px;
	}

	.ltbody_md__bold {
		font-size: 12px;
	}

	.ltbody_sm__regular {
		font-size: 10px;
	}

	.ltbody_sm__medium {
		font-size: 10px;
	}

	.ltbody_sm__semibold {
		font-size: 10px;
	}

	.ltbody_sm__bold {
		font-size: 10px;
	}

	.ltbody_xs__regular {
		font-size: 8px;
	}

	.ltbody_xs__medium {
		font-size: 8px;
	}

	.ltbody_xs__semibold {
		font-size: 8px;
	}

	.ltbody_xs__bold {
		font-size: 8px;
	}

	.mb25 {
		margin-bottom: 16px;
	}
}

/* Mobile */
@media (max-width: 700px) {
	.sidebar_position_style {
		position: fixed;
		z-index: 900000;
		margin-top: 60px;
	}
	.children_position_style {
		position: absolute;
		z-index: 50000;
	}
}
@media (max-width: 768px) {
	.sidebar_position_style {
		position: fixed;
		z-index: 900000;
		margin-top: 60px;
	}
	.children_position_style {
		position: absolute;
	}
}
@media (max-width: 820px) {
	.sidebar_position_style {
		position: fixed;
		z-index: 90000000;
		margin-top: 120px;
	}
	.children_position_style {
		position: absolute;
	}
}
@media (max-width: 1000px) {
	.sidebar_position_style {
		position: fixed;
		z-index: 90000000;
		margin-top: 120px;
	}
	.children_position_style {
		position: absolute;
	}
}

@media (max-width: 1300px) {
	.sidebar_position_style {
		position: 'relative';
		z-index: 999;
		margin-top: 60px;
	}
	.children_position_style {
		position: 'relative';
	}
}

@media (max-width: 1400px) {
	.sidebar_position_style {
		position: 'relative';
		z-index: 999;
		margin-top: 60px;
	}
	.children_position_style {
		position: 'relative';
	}
}

/* Doctor Consultation */
/* ==== Instant Consultation Swiper Slider */
.instantConsultation_swiper_wrapper {
	flex-direction: unset;
	flex-wrap: wrap;
}
